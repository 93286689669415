<template>
  <div>
    <b-card
      class="mb-1"
    >
      <resumenFiltro
        @showAlumnosObjetivos="showAlumnosObjetivos"
        @changeCurso="changeCurso"
      />
    </b-card>
    <b-overlay
      :show="configs.cargandoData"
      spinner-variant="primary"
      :class="configs.cargandoData ? 'mt-5' : ''"
      variant="semi-dark"
    >
      <resumenAlumnosObjetivos
        v-if="mostrarAlumnosObjetivos"
        :objetivos.sync="objetivos"
        :alumnos="alumnos"
        :notasAlumnos.sync="notasAlumnos"
        :idPeriodoActivo="idPeriodoActivo"
        :idCursoSelected="idCursoSelected"
        :idAsignaturaSelected="idAsignaturaSelected"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BOverlay, BAlert,
} from 'bootstrap-vue'

import { mapActions, mapGetters } from 'vuex'

import resumenFiltro from './components/ResumenFiltro.vue'
import resumenAlumnosObjetivos from './components/ResumenAlumnosObjetivos.vue'

export default {
  components: {
    BCard,
    BOverlay,
    BAlert,
    // alumnosList,
    resumenFiltro,
    resumenAlumnosObjetivos,
  },
  data() {
    return {
      configs: {
        cargandoData: false,
      },
      // Globals
      idUsuario: null,
      idEstablecimientoActivo: null,
      idPeriodoActivo: null,
      idCursoSelected: null,
      idAsignaturaSelected: null,
      fetchPuntajesCount: 0,

      // Filtros
      tipoEnseñanzas: [],
      cursos: [],
      asignaturas: [],

      // AlumnosIndicadores
      mostrarAlumnosObjetivos: false,
      alumnos: [],
      objetivos: [],
      notasAlumnos: [],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',

      getObjetivosAsignatura: 'objetivos/getObjetivosAsignatura',
      getAlumnosCurso: 'alumnos/getAlumnosCurso',
      getNotas: 'notas/getNotas',
    }),
    alertSinAsignaturas() {
      return this.tipoEnseñanzas.length
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    ...mapActions({
      fetchObjetivosAsignatura: 'objetivos/fetchObjetivosAsignatura',
      fetchAlumnosCurso: 'alumnos/fetchAlumnosCurso',
      fetchNotasAsignatura: 'notas/fetchNotasAsignatura',
    }),
    initialize() {
      this.idPeriodoActivo = this.getUser.idPeriodoActivo === null
                              ? 3
                              : this.getUser.idPeriodoActivo
    },
    changeCurso() {
      this.mostrarAlumnosObjetivos = false
      this.objetivos = []
      this.alumnos = []
      this.notasAlumnos = []
    },
    async showAlumnosObjetivos(data) {
      this.configs.cargandoData = true
      this.mostrarAlumnosObjetivos = false
      this.objetivos = []
      this.alumnos = []
      this.notasAlumnos = []
      if (data.asignaturaSelected !== null && data.cursoSelected !== null) {
        this.setData(data)
        await this.fetchsAlumnosObjetivos(data)
        this.mostrarAlumnosObjetivos = true
      }
      this.configs.cargandoData = false
    },
    setData(data) {
      const {
        cursoSelected,
        asignaturaSelected,
      } = data

      if (cursoSelected !== null && asignaturaSelected !== null) {
        this.idCursoSelected = cursoSelected
        this.idAsignaturaSelected = asignaturaSelected
      }
    },
    async fetchsAlumnosObjetivos(datos) {
      const params = {
        idAsignatura: datos.asignaturaSelected,
        idPeriodoActivo: this.idPeriodoActivo,
        idCurso: this.idCursoSelected,
      }
      await Promise.all([
        await this.fetchObjetivosAsignatura(params),
        await this.fetchAlumnosCurso(this.idCursoSelected),
        await this.fetchNotasAsignatura(params)
      ])
      this.objetivos = this.getObjetivosAsignatura
      this.alumnos = this.getAlumnosCurso
      this.notasAlumnos = this.getNotas
    },
  },
}
</script>

<style>

</style>

<template>
  <b-overlay
    :show="configs.cargandoTable"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <b-table
      striped
      small
      noCollapse
      hover
      responsive
      sticky-header
      class="mt-1 rounded"
      style="min-height: 638px !important;"
      :current-page="currentPage"
      :items.sync="items"
      :fields="fields"
      :bordered="true"
    >
      <!-- Header: Check -->
      <template #head(colCheck)="data">

        <b-form-checkbox
          :id="data.label"
          v-model="chkTodo"
        />

      </template>

      <!-- Column: Check -->
      <template #cell(colCheck)="data">

        <b-form-checkbox
          :id="`chk-${data.item.id}`"
          v-model="data.item.chkSelected"
        />

      </template>

      <!-- COLUMNAS Indicadores -->
      <template
        #cell()="data"
      >
        <div
          v-if="data.field.key == 'numLista'"
        >
          {{ data.item.numLista }}
        </div>
        <div
          v-else-if="data.field.key == 'alumnos'"
        >
          {{ data.item.alumno }}
        </div>
        <div
          v-else-if="data.field.key == 'abreviaturaDiagnostico'"
        >
          {{ data.item.abreviaturaDiagnostico }}
        </div>
        <div
          v-else-if="data.field.key == 'nombrePrioritario'"
        >
          {{ data.item.nombrePrioritario }}
        </div>

        <notaAlumnoObjetivo
          v-else-if="data.field.objetivo"
          :item.sync="data.item"
          :field="data.field"
          :puntajesIndicadoresTransformacion="getPuntajesIndicadoresTransformacion"
          :idPeriodoActivo="idPeriodoActivo"
          :idCursoSelected="idCursoSelected"
          :idAsignaturaSelected="idAsignaturaSelected"
        />

        <conversionNotas
          v-else-if="data.field.key == 'nota'"
          :item.sync="data.item"
          :field="data.field"
          :idPeriodoActivo="idPeriodoActivo"
        />

        <nivelLogro
          v-else-if="data.field.key == 'nivelLogro'"
          :item.sync="data.item"
          :field="data.field"
          :idPeriodoActivo="idPeriodoActivo"
        />

      </template>

    </b-table>
  </b-overlay>
</template>

<script>
import {
  BTable, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'

import spinner from '../../components/spinner.vue'
import notaAlumnoObjetivo from './alumnosObjetivo/notaAlumnoObjetivo.vue'
import conversionNotas from './alumnosObjetivo/conversionNotas.vue'
import nivelLogro from './alumnosObjetivo/nivelLogro.vue'

export default {
  components: {
    BTable,
    BFormCheckbox,
    BOverlay,
    notaAlumnoObjetivo,
    conversionNotas,
    nivelLogro,
    spinner,
  },
  props: {
    objetivos: {
      type: Array,
      required: true,
    },
    alumnos: {
      type: Array,
      required: true,
    },
    notasAlumnos: {
      type: Array,
      required: true,
    },
    idPeriodoActivo: {
      type: Number,
      required: true,
    },
    idCursoSelected: {
      type: Number,
      required: true,
    },
    idAsignaturaSelected: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      configs: {
        cargandoTable: true,
      },
      // chk
      items: [],
      selectedchk: [],
      chkTodo: null,
      checked: null,

      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      color: [
        {
          nombre: '',
        },
        {
          nombre: 'danger',
        },
        {
          nombre: 'warning',
        },
        {
          nombre: 'success',
        },
        {
          nombre: 'info',
        },
      ],
      fields: [
        {
          key: 'numLista',
          label: '#',
          sortable: false,
          variant: 'primary',
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '1px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'alumnos',
          label: 'Alumnos',
          sortable: false,
          stickyColumn: true,
          variant: 'light',
          tdClass: 'text-left',
          thStyle: {
            'text-align': 'left',
            width: '250px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'abreviaturaDiagnostico',
          label: 'Diagnóstico Pie',
          sortable: false,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '30px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombrePrioritario',
          label: '¿Prioritario?',
          sortable: false,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '30px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldNota: [
        {
          key: 'nota',
          label: 'Nota',
          tdClass: 'text-center',
          variant: 'success',
          thStyle: {
            width: '10px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldNivelLogro: [
        {
          key: 'nivelLogro',
          label: 'Nivel de logro',
          tdClass: 'text-center',
          variant: 'success',
          thStyle: {
            width: '10px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getPuntajesIndicadoresTransformacion: 'puntajesIndicadores/getPuntajesIndicadoresTransformacion',
      getPuntajesIndicadores: 'puntajesIndicadores/getPuntajesIndicadores',
    }),
  },
  mounted() {
    this.inicialize()
  },
  methods: {
    ...mapActions({
      fetchPuntajesIndicadoresTransformacion: 'puntajesIndicadores/fetchPuntajesIndicadoresTransformacion',
      fetchPuntajesIndicadores: 'puntajesIndicadores/fetchPuntajesIndicadores',
    }),
    setAll() {
      this.setObjetivos(this.objetivos)
    },
    async inicialize() {
      await this.fetchPuntajesIndicadoresTransformacion()
      this.setObjetivos(this.objetivos)
      this.setItems(this.alumnos, this.notasAlumnos)
    },
    setObjetivos(objetivos) {
      objetivos.sort((a, b) => a.abreviatura.localeCompare(b.abreviatura))
      objetivos.forEach(objetivo => {
        const tipoObjetivo = objetivo.idEstablecimiento ? 'Interno' : 'Ministerio'
        this.fields.push({
          key: `${objetivo.id}`,
          label: `${objetivo.abreviatura}`,
          tipoObjetivo,
          headerTitle: objetivo.nombreObjetivo,
          tdClass: 'text-center',
          variant: 'secondary',
          objetivo: true,
          thStyle: {
            width: '60px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        })
      })
      this.fields.push(this.fieldNota)
      this.fields.push(this.fieldNivelLogro)
    },
    setItems(alumnos, notas) {
      // consultar notas por indicador en este curso
      this.items = []
      alumnos.forEach(alumno => {
        const nombre = `${alumno.primerApellido} ${alumno.segundoApellido} ${alumno.nombres}`
        const notasAlumno = notas.filter(p => p.idAlumno === alumno.id)
        this.items.push({
          idCurso: alumno.idCurso,
          idAsignatura: alumno.id,
          id: alumno.id,
          numLista: alumno.numLista,
          rut: alumno.rut,
          alumno: nombre,
          nombreDiagnostico: alumno.nombreDiagnostico,
          abreviaturaDiagnostico: alumno.abreviaturaDiagnostico,
          nombrePrioritario: alumno.nombrePrioritario,
          notasAlumno,
        })
      })
      this.configs.cargandoTable = false
    },
    // Checkbox select item Table
    chkAll() {
      this.items.forEach(item => {
        const cliente = this.items.find(i => i.id === item.id)
        cliente.chkSelected = this.chkTodo
      })
    },
    chkCount() {
      let chkCount = 0
      this.items.forEach(item => {
        chkCount = item.chkSelected
          ? chkCount + 1
          : chkCount
      })
      return chkCount === 0
    },

    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of
      // buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>

<template>
  <b-row>
    <b-col id="cursos_select"
      cols="12"
      md="6"
    >
      <b-form-group
        label="Cursos"
        label-for="cursos"
      >
        <b-overlay
          :show="configs.cargandoCursos"
          spinner-variant="primary"
          variant="semi-dark"
        >
          <v-select
            v-model="cursoSelected"
            placeholder="Selecciona un curso"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="cursosOptions"
            label="nombre"
            :reduce="option => option.id"
            @input="changeCurso"
          >
            <template #no-options>
              Ups! no tienes cursos asignados.
            </template>
          </v-select>
        </b-overlay>
      </b-form-group>
    </b-col>

    <b-col id="asignaturas_select"
      cols="12"
      md="6"
    >
      <b-form-group
        label="Asignaturas"
        label-for="asignaturas"
      >
        <b-overlay
          :show="configs.cargandoAsignaturas"
          spinner-variant="primary"
          variant="semi-dark"
        >
          <v-select
            v-model="asignaturaSelected"
            placeholder="Selecciona una asignatura"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="asignaturasOptions"
            label="nombre"
            :reduce="option => option.id"
            :disabled="asignaturasOptions.length === 0"
            @input="changeAsignatura"
          />
          <template #no-options>
            Ups! no tienes cursos asignados.
          </template>
        </b-overlay>
      </b-form-group>
    </b-col>

  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BRow, BCol, BFormGroup, BOverlay,
    vSelect,
  },
  data() {
    return {
      configs: {
        cargandoCursos: true,
        cargandoAsignaturas: false,
        showAlert: false,
      },
      cursoSelected: null,
      asignaturaSelected: null,
      cursosOptions: [],
      asignaturasOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getPeriodoHistorico: 'periodos/getPeriodoHistorico',
    }),
  },
  watch: {
    getPeriodoHistorico() {
      this.cursoSelected = null
      this.asignaturaSelected = null
      this.changeAsignatura()
      this.asignaturasOptions = []
      this.loadCursos(this.getUser.id)
    },
  },
  mounted() {
    this.loadCursos(this.getUser.id)
  },
  methods: {
    ...mapActions({
      fetchCursosUsuario: 'cursos/fetchCursosUsuario',
      fetchAsignaturasUsuario: 'asignaturas/fetchAsignaturasUsuario',
    }),
    async loadCursos() {
      this.configs.cargandoCursos = true
      const response = await this.fetchCursosUsuario()
      await this.setCursos(response)
      this.configs.cargandoCursos = false
    },
    async loadAsignaturas(id_curso) {
      this.configs.cargandoAsignaturas = true
      const response = await this.fetchAsignaturasUsuario(id_curso)
      await this.setAsignaturas(response)
      this.configs.cargandoAsignaturas = false
    },
    setCursos(cursos) {
      this.cursosOptions = []
      cursos.forEach(curso => {
        const nombre = `${curso.nombreGrado} ${curso.letra}`
        this.cursosOptions.push({
          id: curso.id,
          nombre,
          idNivel: curso.idNivel,
        })
      })
    },
    setAsignaturas(asignaturas) {
      this.asignaturasOptions = []
      asignaturas.forEach(asignatura => {
        this.asignaturasOptions.push({
          id: asignatura.id,
          nombre: asignatura.nombre
        })
      })
    },
    changeCurso() {
      this.asignaturaSelected = null
      this.loadAsignaturas(this.cursoSelected)
      this.$emit('changeCurso')
    },
    changeAsignatura() {
      const params = {
        cursoSelected: this.cursoSelected,
        asignaturaSelected: this.asignaturaSelected,
      }
      this.$emit('showAlumnosObjetivos', params)
    },
  },
}
</script>

<style>

</style>

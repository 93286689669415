<template>
  <div
  >
    <spinner
      v-if="showSpinner"
      size="small"
    />
    {{ nivelLogro }}
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import spinner from '../../../components/spinner.vue'

export default {
  components: {
    spinner,
  },
  data() {
    return {
      nivelLogro: null,
      variant: 'secondary',
      conversionNivelLogro: [
        {
          nivelLogro: 'N.O.',
          desde: '2.0',
          hasta: '2.9',
        },
        {
          nivelLogro: 'SL',
          desde: '3.0',
          hasta: '3.9',
        },
        {
          nivelLogro: 'PL',
          desde: '4.0',
          hasta: '4.9',
        },
        {
          nivelLogro: 'ML',
          desde: '5.0',
          hasta: '5.9',
        },
        {
          nivelLogro: 'L',
          desde: '6.0',
          hasta: '7.0',
        },
      ],
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    // Ripple,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    idPeriodoActivo: {
      type: Number,
      required: true,
    },
  },
  watch: {
    // getPuntajesIndicadores() {
    //   this.setConversionNota(this.item.notasAlumno)
    // },
  },
  computed: {
    ...mapGetters({
      getNotaConversion: 'notasConversion/getNotaConversion',
      getPuntajesIndicadores: 'puntajesIndicadores/getPuntajesIndicadores',
    }),
    showSpinner() {
      return false
    },
  },
  mounted() {
    this.setConversionNota(this.item.notasAlumno)
  },
  methods: {
    ...mapActions({
      fetchNotaConversion: 'notasConversion/fetchNotaConversion',
    }),
    setConversionNota(nota) {
      if (nota.length) {
        const cantidadObjetivos = nota.length
        let sumaNotas = 0
        nota.forEach(n => {
          sumaNotas += parseFloat(n.nota)
        })
        let promedio = sumaNotas / cantidadObjetivos

        const aproximar = false
        if (aproximar) {
          promedio = Math.round(promedio)
          // promedio = promedio.toFixed(2);
        } else {
          promedio = promedio.toFixed(1)
        }
        this.setNivelLogro(promedio)
      } else {
        this.nivelLogro = '-'
      }
    },
    setNivelLogro(nota) {
      this.conversionNivelLogro.forEach(conv => {
        if (parseFloat(nota) >= parseFloat(conv.desde) && parseFloat(nota) <= parseFloat(conv.hasta)) {
          this.nivelLogro = conv.nivelLogro
        }
      })
    },
  },
}
</script>

<template>
  <div
    class="rounded pt-25"
    :style="`background: ${color};color: ${textColor}; height:26px !important;`"
  >
    <spinner
      v-if="showSpinner"
      size="small"
    />
    {{ promedio }}
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import { formatos } from '@core/mixins/ui/formatos'
import spinner from '../../../components/spinner.vue'

export default {
  components: {
    spinner,
  },
  mixins: [formatos],
  data() {
    return {
      promedio: null,
      color: null,
      textColor: null,
      variant: 'secondary',
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    // Ripple,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    idPeriodoActivo: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getNotaConversion: 'notasConversion/getNotaConversion',
      getPuntajesIndicadores: 'puntajesIndicadores/getPuntajesIndicadores',
    }),
    showSpinner() {
      return false
    },
  },
  mounted() {
    this.setConversionNota(this.item.notasAlumno)
    // Obtener los datos de la tabla conversion notas y mostrar la nota correspondiente
    // al promedio de los puntajes (cantidad indicadores y puntaje obtenido)
  },
  methods: {
    ...mapActions({
      fetchNotaConversion: 'notasConversion/fetchNotaConversion',
    }),

    setColor(nota) {
      this.color = null
      this.textColor = null
      const { color, textColor } = this.formatoColoresNotas(nota)
      this.color = color
      this.textColor = textColor
    },
    setConversionNota(nota) {
      if (nota.length) {
        const cantidadObjetivos = nota.length
        let sumaNotas = 0
        nota.forEach(n => {
          sumaNotas += parseFloat(n.nota)
        })
        let promedio = sumaNotas / cantidadObjetivos

        const aproximar = true
        if (aproximar) {
          promedio = promedio.toFixed(1)
        } else {
          promedio = promedio.toFixed(1)
        }
        promedio = parseFloat(promedio) === 3.9 ? '4.0' : promedio

        this.promedio = promedio
        this.setColor(this.promedio)
      } else {
        this.promedio = '-'
        this.setColor(null)
      }
    },
  },
}
</script>

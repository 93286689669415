<template>
  <div
    class="text-center"
  >
    <spinner
      v-if="showSpinner"
      size="small"
    />

    <div v-if="nota !== null"
      class="d-block justify-content-between align-items-center "
    >
      <div class="text-center ml-50 mr-50">
        {{ nivelLogro }}
      </div>
      <div class="pl-50 pr-50 mr-0 text-center rounded" :style="`background: ${color}; color: ${textColor};`">
        {{ nota }}
      </div>
    </div>
    <div
      v-else-if="nota === null"
    >
      -
    </div>
    <!-- :style="`margin: -2px !important; background: ${color}; color: ${textColor};`" -->

    <!-- <div
      v-if="nota !== null"
      :style="`width: 45%; float:left; margin-right: 3px !important; background: ${color}; color: ${textColor};`"
    >
      {{ nota }}
    </div>

    <div
      v-if="nota !== null"
      style="float:left;"
    >
      {{ nivelLogro === null ? '' : ` | ${nivelLogro}` }}
    </div> -->

  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import { formatos } from '@core/mixins/ui/formatos'
import spinner from '../../../components/spinner.vue'

export default {
  components: {
    spinner,
  },
  mixins: [formatos],
  data() {
    return {
      nota: null,
      nivelLogro: null,
      color: null,
      textColor: null,
      puntajesOption: [],
      autores: null,
      conversionNivelLogro: [
        {
          nivelLogro: 'N.O.',
          desde: '2.0',
          hasta: '2.9',
        },
        {
          nivelLogro: 'SL',
          desde: '3.0',
          hasta: '3.9',
        },
        {
          nivelLogro: 'PL',
          desde: '4.0',
          hasta: '4.9',
        },
        {
          nivelLogro: 'ML',
          desde: '5.0',
          hasta: '5.9',
        },
        {
          nivelLogro: 'L',
          desde: '6.0',
          hasta: '7.0',
        },
      ],
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    // Ripple,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    modulo: {
      type: String,
      required: false,
    },
    puntajesIndicadoresTransformacion: {
      type: Array,
      required: true,
    },
    idPeriodoActivo: {
      type: Number,
      required: true,
    },
    idCursoSelected: {
      type: Number,
      required: true,
    },
    idAsignaturaSelected: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
    }),
    showSpinner() {
      return false
    },
  },
  watch: {
  },
  mounted() {
    if (this.item.notasAlumno.length > 0) {
      this.setNotas(this.item.notasAlumno, this.field)
    }
  },
  methods: {
    ...mapActions({
      fetchNotaConversion: 'notasConversion/fetchNotaConversion',
    }),
    setColor(nota) {
      this.color = null
      this.textColor = null
      const { color, textColor } = this.formatoColoresNotas(nota)
      this.color = color
      this.textColor = textColor
    },
    setNotas(notas, field) {
      const notaObjetivo = notas.find(n => n.idObjetivo === Number(field.key) && n.tipoObjetivo === field.tipoObjetivo)
      this.nota = typeof notaObjetivo !== 'undefined'
        ? notaObjetivo.nota
        : null
      this.setColor(this.nota)
      this.setNivelLogro(this.nota)
    },
    setNivelLogro(nota) {
      this.conversionNivelLogro.forEach(conv => {
        if (parseFloat(nota) >= parseFloat(conv.desde) && parseFloat(nota) <= parseFloat(conv.hasta)) {
          this.nivelLogro = conv.nivelLogro
        }
      })
    },
  },
}
</script>
